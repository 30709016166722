@import url(https://fonts.googleapis.com/css2?family=Redressed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*

LOGIN / SIGNUP

*/
.login-signup-form-container {
  text-align: left;
  padding: 1em;
  border: 2px solid #d3d3d3;
  border-radius: .5em;
  width: 600px;
  margin-top: 100px;
  font-size: 1.2rem;
}

.login-signup-form {
  padding: 1em;
}
.login-signup-form Button {
  background-color: #F7D5DC;
  color: black;
  border: none;
}
.small-text {
  font-size: 1rem;
  margin: auto;
  margin-top: 2em;
}
.small-link {
  text-decoration: underline;
}

/*

NAVIGATION

*/
.navigation {
  display: flex;
  justify-content: space-between;
  height: 10vh auto;
  margin: auto;
  font-family: 'Redressed', cursive;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
}
.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.5em;
}
.logo-container p {
  font-size: 2.25rem;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 100%;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 1rem;
  font-size: 1.75rem;
}
.link {
  padding: 1rem;
  color: black;
}
.link:hover {
  text-decoration: none;
  color: black;
}
.nav-button {
  padding: 0.75rem;
  border: none;
  text-decoration: underline;
  color: black;
  background-color: unset;
}

/* 

LANDING

*/

/* section 1 */
.section1 {
  width: 100%;
  height: 100vh;
  margin: auto;
  position: relative;
  background-image: url(/static/media/brooke-lark-wMzx2nBdeng-unsplash.cdf7f93b.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section1 .content {
  display: flex;
  padding: 10vh;
  height: 30vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.section1 h1{
  position: relative;
  color: black;
  font-family: 'Redressed', cursive;
  font-size: 6rem;
  opacity: 0.2;
  text-decoration: underline;
}
.section1 h1:hover {
  opacity: 0.4;
  transition: 0.2s;
}
.landing-navigation {
  display: flex;
  padding-top: 30vh;
  width: 25vw;
  justify-content: space-between;
  align-items: center;
}
.landing-link {
  color: black;
  opacity: 0.4;
  font-family: 'Redressed', cursive;
  font-size: 2rem;
}
.landing-link:hover {
  color: black;
  text-decoration: none;
  opacity: 0.6;
}

/* section 2 */
.section2 {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: auto;
  background-image: url(/static/media/eaters-collective-12eHC6FxPyg-unsplash.74acbe41.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.section2 p {
  font-size: 2rem;
  width: 25vw;
  margin-left: 2rem;
}

/* section 3 */
.section3 {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: auto;
  background-image: url(/static/media/brooke-lark-W9OKrxBqiZA-unsplash.b012affb.jpg);
  background-size: cover;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.section3 p {
  transform: rotateY(180deg);
  font-size: 2rem;
  width: 25vw;
  margin-top: 10vh;
}
.section3 .CTA {
  transform: rotateY(180deg);
  font-size: 2rem;
  width: 25vw;
  margin-top: 2rem;
  text-decoration: underline;
  color: black;
}




.recipes-dashboard {
  background-image: url(/static/media/brooke-lark-29h2n639YDA-unsplash.19cdd7eb.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.recipes-dashboard .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: auto;
  padding: 2rem;
}
.recipes-dashboard .header h1 {
  padding-bottom: 1rem;
}
.searchbar {
  width: 20rem
}
.recipes-container {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 90%;
  min-height: 50%;

}
.recipe-small-container {
  margin: auto;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.recipe-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem;
  font-size: 1.5rem;
}
.recipe-link {
  color: black;
}
.recipe-link:hover {
  color: black;
}






/* 

DETAILED RECIPE

*/
.recipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
}
.recipe-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* 

LOADING

*/
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/lukas-blazek-f-TWhXOrLiU-unsplash.e9429b2a.jpg);
  background-size: cover;
  background-position: top right;
  min-height: 100vh;
}
.loading-container h1 {
  display: flex;
  font-size: 5rem;
}
